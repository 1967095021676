<template>
  <v-card class="pa-5" height="100%">
    <v-row width="100%" justify="start" align="center" class="ma-0 pa-0">
      <span class="title-idx-small">Servicios más solicitados en Tway</span>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-img
            v-bind="attrs"
            v-on="on"
            class="info-services"
            width="20px"
            max-width="20px"
            :src="require('@/assets/img/icons/info.svg')"
          />
        </template>
        <div>
          <span>Aquí podrás ver los Servicios más requeridos para mejorar el Índice de Transformación Digital</span>
        </div>
      </v-tooltip>
    </v-row>
    <v-container fluid class="ma-0 pa-0">
      <v-row
        v-for="(element, elementIndex) in rankingServices"
        :key="elementIndex"
        :style="'font-size: ' + (30 - elementIndex * 2) + 'px !important;'"
        class="mb-1 ma-0 pa-0"
        width="100%"
        justify="space-between"
        align="center"
      >
        <div style="display: flex; flex-direction: row; align-items: center">
          <strong style="color: #7319d5" class="roboto mr-4">{{ element }}</strong>
        </div>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
export default {
  data() {
    return {
      rankingServices: [
        "Marketing Digital",
        "Diseño UX/UI",
        "Experiencia Cliente",
        "Ciberseguridad",
        "Tecnologías Inteligentes",
        "Servicio Infraestructura TI",
        "Gestión Servicios TI",
        "Servicios TI Gobernanza",
        "Automatización de Procesos",
        "Data y Analitica",
      ],
    };
  },
  computed: {
    mobile() {
      return this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs;
    },
    desktop() {
      return this.$vuetify.breakpoint.md || this.$vuetify.breakpoint.lg || this.$vuetify.breakpoint.xl;
    },
  },
};
</script>

<style lang="scss" scoped>
.title-idx-small {
  font-family: Josefin Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  line-height: 30px;
  .element {
    text-transform: lowercase;
  }
  text-align: start;
}

.info-services {
  margin-left: 10px;
}
</style>
