<template>
  <v-card class="pa-5" height="100%">
    <v-row width="100%" justify="start" align="start" class="ma-0 pa-0">
      <v-img width="100%" max-width="85px" :src="require('@/assets/img/icons/' + image)" />
      <v-col>
        <v-row width="100%" justify="start" align="start" class="ma-0 pa-0">
          <span class="title-idx-text">Desafío Estratégico</span>
        </v-row>
        <v-row width="100%" justify="start" align="start" class="ma-0 pa-0">
          <strong style="font-weight: bold !important" class="title-idx-text text-capitalize">{{
            worseDimension.name
          }}</strong>
        </v-row>
      </v-col>
    </v-row>
    <v-row width="100%" justify="center" align="center" class="ma-0 mt-4 pa-0">
      <bars :values="worseDimension.subDimensions" />
    </v-row>
    <span class="roboto" style="font-size: 16px; line-height: 20px; white-space: pre-line; word-wrap: break-word">{{
      worseDimension.advice
    }}</span>
    <v-row width="100%" justify="center" align="center" class="ma-0 mt-4 pa-0">
      <v-btn
        depressed
        rounded
        x-large
        color="tway-violet"
        class="white--text"
        :to="{
          name: 'dashboardimension',
          params: { id: worseDimension.code },
        }"
      >
        ver {{ worseDimension.name }}
      </v-btn>
    </v-row>
  </v-card>
</template>

<script>
import BarsItems from "@/components/dashboards/IMTD/BarsItems";

export default {
  components: {
    bars: BarsItems,
  },
  data() {
    return {
      image: "",
    };
  },
  props: {
    worseDimension: {
      type: Object,
      default: () => {},
    },
  },
  created() {
    /* eslint-disable */
    const a = this.worseDimension.name;
    this.image =
      a === "Modelo de Negocio"
        ? "bars.svg"
        : a === "Facilitadores"
        ? "console.svg"
        : a === "Mentalidad"
        ? "users.svg"
        : a === "Orquestación"
        ? "barsup.svg"
        : " ";
    /* eslint-enable */
  },
  computed: {
    mobile() {
      return this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs;
    },
    desktop() {
      return this.$vuetify.breakpoint.md || this.$vuetify.breakpoint.lg || this.$vuetify.breakpoint.xl;
    },
  },
};
</script>

<style></style>
