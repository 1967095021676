<template>
  <v-card class="pa-5" style="height: 100%">
    <v-row width="100%" justify="start" align="center" class="mb-6 ma-0 pa-0">
      <span class="title-idx-text-nowidth mr-4">Benchmark Especialistas Tway </span>
      <v-tooltip class="tooltip" bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-img
            v-bind="attrs"
            v-on="on"
            class="image-percentage"
            width="20px"
            max-width="20px"
            :src="require('@/assets/img/icons/info.svg')"
          />
        </template>
        <div>
          <span>Aquí podrás ver algunas posiciones según las Propuestas de Valor en Tway</span>
        </div>
      </v-tooltip>
    </v-row>
    <v-row class="ma-0 pa-1" style="height: 90%">
      <v-col cols="12" md="4" :class="desktop ? 'pr-2' : ''" class="pb-4 pa-0">
        <v-card color="#9652DF" class="white--text rounded-xl pa-5 center-full">
          <div>
            <v-row class="mb-6 ma-0 pa-0" width="100%" justify="center" align="center">
              <div class="mr-2 place-icon-first">
                <span v-if="company.checked" class="white--text">{{ rankingClientYear }}</span>
                <v-img
                  class="image-percentage"
                  width="70px"
                  max-width="70px"
                  :src="require('@/assets/img/icons/place-white.svg')"
                  v-if="company.checked"
                />

                <v-img
                  width="70px"
                  max-width="70px"
                  :src="require('@/assets/img/icons/0_ranking_white.svg')"
                  v-if="!company.checked"
                />
              </div>
            </v-row>
            <span v-if="company.checked" class="no-ranking-text">
              Tu posición en el ranking según tus años de experiencia
            </span>
            <span v-if="!company.checked" class="no-ranking-text">
              Para saber tu posición según tus años de experiencia, debes completar este dato en tu perfil.
            </span>
          </div>
        </v-card>
      </v-col>
      <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4" class="pb-4 pa-0">
        <v-card color="#B98CEA" class="white--text rounded-xl pa-5 center-full">
          <div>
            <v-row class="mb-6 ma-0 pa-0" width="100%" justify="center" align="center">
              <div class="mr-2 place-icon-first">
                <span class="white--text" v-if="company.checked">{{ rankingClientSize }}</span>
                <v-img
                  class="image-percentage"
                  width="70px"
                  max-width="70px"
                  :src="require('@/assets/img/icons/place-white.svg')"
                  v-if="company.checked"
                />
                <v-img
                  width="70px"
                  max-width="70px"
                  :src="require('@/assets/img/icons/0_ranking_white.svg')"
                  v-if="!company.checked"
                />
              </div>
            </v-row>
            <span v-if="company.checked" class="no-ranking-text">
              Tu posición en el ranking según el tamaño de tu empresa
            </span>
            <span v-if="!company.checked" class="no-ranking-text">
              Para saber tu posición según el tamaño de tu empresa, debes completar este dato en tu perfil.
            </span>
          </div>
        </v-card>
      </v-col>
      <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4" :class="desktop ? 'pl-2' : ''" class="pb-4 pa-0">
        <v-card color="#DCC5F4" class="white--text rounded-xl pa-5 center-full">
          <div>
            <v-row class="mb-6 ma-0 pa-0" width="100%" justify="center" align="center">
              <div class="mr-2 place-icon-first">
                <v-img
                  class="image-percentage"
                  width="70px"
                  max-width="70px"
                  :src="require('@/assets/img/icons/user-admin.svg')"
                />
              </div>
            </v-row>
            <span>
              <strong
                >Nivel <br />
                <span v-if="clientLevel">{{ clientLevel }}</span>
                <v-progress-circular v-else indeterminate :size="20" :width="2" color="white" />
              </strong>
              <br />
              En Cliente
            </span>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
export default {
  props: {
    company: {
      type: Object,
      default: () => {},
    },
    rankingClientYear: {
      type: Number,
      default: 0,
    },
    rankingClientSize: {
      type: Number,
      default: 0,
    },
    clientLevel: {
      type: String,
      default: "",
    },
  },
  computed: {
    mobile() {
      return this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs;
    },
    desktop() {
      return this.$vuetify.breakpoint.md || this.$vuetify.breakpoint.lg || this.$vuetify.breakpoint.xl;
    },
  },
};
</script>

<style lang="scss" scoped>
.no-ranking-text {
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: normal !important;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
}
.title-idx-text-nowidth {
  font-size: 23px;
  .element {
    text-transform: lowercase;
  }
  line-height: 30px;
  font-weight: 400;
  text-align: start;
}
.center-full {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  > div {
    > span {
      strong {
        font-size: 20px !important;
      }
      text-align: center;
      min-height: 100px;
      font-weight: 600;
      font-size: 17px;
    }
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
}
.place-icon-first {
  display: flex;
  flex-direction: column;
  align-items: center;
  span {
    margin-bottom: -50px;
    font-size: 35px;
    font-weight: bold;
    color: #16c62e;
  }
}
</style>
