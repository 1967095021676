<template>
  <div>
    <Breadcrumbs :items="breadcrumbs" />
    <Loader :visible="isLoading" />
    <v-container fluid class="pa-0 ma-0">
      <v-row class="ma-0 pa-0" align="stretch">
        <ManagementFilter
          @loading="loading"
          :loading="isLoading"
          :business-areas="getCompanyBusinessAreas"
          :managements="getCompanyManagements"
        />
      </v-row>
      <v-row v-if="!isLoading" class="ma-0 pa-0" align="stretch">
        <DashboardSpecialist v-if="getCompany.type === 'SPECIALIST'" />
        <DashboardClient v-if="getCompany.type === 'CLIENT'" :ordered-data="orderedData" />
      </v-row>
    </v-container>
  </div>
</template>

<script>
import DashboardClient from "@/components/dashboards/IMTD/client/DashboardClient";
import DashboardSpecialist from "@/components/dashboards/IMTD/specialist/DashboardSpecialist";
import Loader from "@/components/Loader";
import Breadcrumbs from "@/components/Breadcrumbs";
import { mapActions, mapGetters } from "vuex";
import ManagementFilter from "@/components/dashboards/IMTD/ManagementFilter.vue";

export default {
  components: {
    DashboardClient,
    DashboardSpecialist,
    Loader,
    Breadcrumbs,
    ManagementFilter,
  },

  data: () => ({
    breadcrumbs: [
      {
        text: "Inicio",
        disabled: false,
        exact: true,
        to: {
          name: "home",
        },
      },
      {
        text: "Dashboard TD",
        disabled: true,
        exact: true,
        to: {
          name: "dashboard",
        },
      },
    ],
    isLoading: true,
  }),
  async created() {
    await this.setCompanyProfile();

    let tokens = [];
    for (let i = 0; i < 2; i++) {
      tokens[i] = await this.$recaptcha("dashboard");
    }
    let promises = [];
    if (this.getCompany.type == "CLIENT") {
      promises = [
        this.setRecommendations({ clientId: this.getClientId }),
        this.setRankingGlobalNumber(),
        this.setRankingYearsClient(this.getClientId),
        this.setRankingSectorNumber(this.getCompany),
        this.setRankingGlobal(this.getCompany.type),
        this.setBusinessAreas(tokens[0]),
        this.setManagements(tokens[1]),
      ];
    } else {
      promises = [
        this.setRecommendations({ clientId: this.getClientId }),
        this.setOdometer(this.getCompany.id),
        this.setRankingYearsClient(this.getClientId),
        this.setRankingSize(this.getClientId),
        this.setBusinessAreas(tokens[0]),
        this.setManagements(tokens[1]),
      ];
    }
    await Promise.all(promises);
    this.isLoading = false;
  },
  methods: {
    ...mapActions("ImtdStore", ["setRecommendations"]),
    ...mapActions("DigitalTransformationStore", [
      "setRankingSectorNumber",
      "setRankingGlobalNumber",
      "setRankingGlobal",
      "setRankingYearsClient",
    ]),
    ...mapActions("CompanyStore", [
      "setBusinessAreas",
      "setManagements",
      "setCompanyProfile",
      "setOdometer",
      "setRankingSize",
    ]),
    loading(event) {
      this.isLoading = event;
    },
  },
  computed: {
    ...mapGetters("authentication", ["getCorporative"]),
    ...mapGetters("UserStore", ["getClientId"]),
    ...mapGetters("ImtdStore", ["getDimensions"]),
    ...mapGetters("CompanyStore", ["getCompany", "getCompanyBusinessAreas", "getCompanyManagements"]),
    orderedData() {
      let series = [{ data: [0, 0, 0, 0] }];
      let dimensions = [];
      series[0].data[0] = this.getDimensions.find((e) => e.name == "Orquestación").score;
      series[0].data[1] = this.getDimensions.find((e) => e.name == "Modelo de Negocio").score;
      series[0].data[2] = this.getDimensions.find((e) => e.name == "Facilitadores").score;
      series[0].data[3] = this.getDimensions.find((e) => e.name == "Mentalidad").score;
      dimensions[0] = this.getDimensions.find((e) => e.name == "Modelo de Negocio");
      dimensions[1] = this.getDimensions.find((e) => e.name == "Facilitadores");
      dimensions[2] = this.getDimensions.find((e) => e.name == "Mentalidad");
      dimensions[3] = this.getDimensions.find((e) => e.name == "Orquestación");

      return { series, dimensions };
    },

    mobile() {
      return this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs;
    },
    desktop() {
      return this.$vuetify.breakpoint.lg || this.$vuetify.breakpoint.xl;
    },
  },
};
</script>

<style>
.select-container {
  min-width: 22em;
}
</style>
