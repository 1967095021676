<template>
  <v-card class="pa-5" height="100%">
    <v-row width="100%" justify="start" align="center" class="mb-6 ma-0 pa-0">
      <span class="title-idx-text">Índice por Dimensiones</span>
    </v-row>
    <v-row class="ma-0 pa-0">
      <v-col class="ma-0 pa-0" cols="12" md="6">
        <apexchart type="radar" :options="chartOptions" :series="series" />
      </v-col>
      <v-col class="ma-0 py-0 pr-0" cols="12" md="6">
        <percentages :values="orderedData" />
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import PercentageItems from "@/components/dashboards/IMTD/PercentageItems";

export default {
  components: {
    percentages: PercentageItems,
  },
  props: {
    orderedData: {
      type: Array,
      default: () => [],
    },
    series: {
      type: Array,
      default: () => [{ data: [0, 0, 0, 0] }],
    },
  },
  data() {
    return {
      chartOptions: {
        chart: {
          toolbar: {
            show: false,
          },
        },
        xaxis: {
          categories: [["ORQUESTACIÓN"], ["MODELO", "DE", "NEGOCIO"], "FACILITADORES", ["MENTALIDAD"]],
        },
        stroke: {
          show: true,
          width: 2,
          colors: ["#15c62e"],
          dashArray: 0,
        },
        markers: {
          size: 0,
          hover: {
            size: 10,
          },
        },
        fill: {
          opacity: 0.15,
          colors: ["#7D00FF"],
        },
        plotOptions: {
          radar: {
            size: 80,
            offsetX: 0,
            offsetY: 0,
            polygons: {
              strokeColors: "#e9dbf9",
              strokeWidth: 1,
              strokeDash: 4,
              connectorColors: "#DCC5F4",
              fill: {
                colors: undefined,
              },
            },
          },
        },
        labels: {
          show: true,
          style: {
            colors: ["#000000"],
            fontSize: "13px",
            fontFamily: "Roboto Condensed",
          },
        },
      },
    };
  },
};
</script>

<style></style>
