<template>
  <v-card class="pa-5" height="100%">
    <v-row width="100%" justify="start" align="center" class="mb-6 ma-0 pa-0">
      <span class="title-idx-text-nowidth mr-4">Impacto por Sector </span>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-img
            v-bind="attrs"
            v-on="on"
            class="image-percentage"
            width="20px"
            max-width="20px"
            :src="require('@/assets/img/icons/info.svg')"
          />
        </template>
        <div>
          <span>Aquí podrás ver el Nivel de Impacto de los servicios que ofreces en el sector</span>
        </div>
      </v-tooltip>
    </v-row>
    <v-row
      class="ma-0 pa-0"
      :style="mobile ? 'margin-right: -11% !important;' : 'margin-right: -8% !important;'"
      justify="center"
    >
      <v-row
        :style="mobile ? 'max-width: 75%;' : 'max-width: 70%; z-index: 10;'"
        class="ma-0 pa-0"
        justify="space-between"
      >
        <div
          style="
            text-align: center;
            font-size: 10px;
            margin-bottom: -20% !important;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
          "
        >
          <span> ANÁLOGO </span>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-img
                v-bind="attrs"
                v-on="on"
                class="image-percentage"
                width="20px"
                height="20px"
                max-height="20px"
                max-width="20px"
                :src="require('@/assets/img/icons/info.svg')"
              />
            </template>
            <div>
              <span>Nivel Básico</span>
            </div>
          </v-tooltip>
        </div>
        <div
          style="
            text-align: center;
            font-size: 10px;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
          "
        >
          <span>DIGITAL</span>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-img
                v-bind="attrs"
                v-on="on"
                class="image-percentage"
                width="20px"
                height="20px"
                max-height="20px"
                max-width="20px"
                :src="require('@/assets/img/icons/info.svg')"
              />
            </template>
            <div>
              <span>Nivel Inicial y Estratégico</span>
            </div>
          </v-tooltip>
        </div>
        <div
          style="
            text-align: center;
            font-size: 10px;
            margin-bottom: -20% !important;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
          "
        >
          <span>
            EN
            <br />
            TRANSFORMACIÓN
          </span>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-img
                v-bind="attrs"
                v-on="on"
                class="image-percentage"
                width="20px"
                height="20px"
                max-height="20px"
                max-width="20px"
                :src="require('@/assets/img/icons/info.svg')"
              />
            </template>
            <div>
              <span>Nivel Competitivo</span>
            </div>
          </v-tooltip>
        </div>
      </v-row>
    </v-row>
    <v-row class="ma-0 py-4 pa-0" justify="center">
      <div
        class="gauge"
        :style="
          'width: 200px; --rotation:70deg; --rotationdiv:' +
          (flagRanking == 1 ? ((odometer.percentage * 180) / 100).toString() : facilitatorDimension.score) +
          'deg; --color:#E57373; --background:#e9ecef;'
        "
      >
        <div class="percentage" />
        <div style="transform: rotate(50deg); background-color: #ffee58" class="percentage" />
        <div style="transform: rotate(130deg); background-color: #8ae296" class="percentage" />
        <div class="mask" />
        <span class="value">
          <div>
            <div><div /></div>
          </div>
        </span>
      </div>
    </v-row>
    <v-row width="100%" justify="center" align="center" class="mb-6 ma-0 pa-0">
      <v-btn
        depressed
        rounded
        small
        :color="flagRanking == 1 ? '#DCC5F4' : '#f1f1f1'"
        @click="
          () => {
            flagRanking = 1;
          }
        "
        class="roboto mr-2"
        :style="
          (flagRanking == 1 ? 'color: #7319D5;' : 'color: #999999;') + ' text-transform: capitalize; font-size: 14px;'
        "
      >
        Servicios
      </v-btn>
      <v-btn
        depressed
        rounded
        small
        :color="flagRanking == 2 ? '#DCC5F4' : '#f1f1f1'"
        class="roboto"
        @click="
          () => {
            flagRanking = 2;
          }
        "
        :style="
          (flagRanking == 2 ? 'color: #7319D5;' : 'color: #999999;') + ' text-transform: capitalize; font-size: 14px;'
        "
      >
        <span>Facilitador</span>
      </v-btn>
    </v-row>
    <div v-if="flagRanking == 1" class="roboto text-center text-impacto">
      Los servicios que ofreces tienen este impacto en las dimensiones
      <strong v-for="(dimension, dimensionIndex) in odometer.dimensions" :key="dimensionIndex"
        >{{ dimension.name }}<span v-if="dimensionIndex != odometerDimensions.length - 1">, </span> </strong
      >.
    </div>
    <div v-else class="roboto text-center text-impacto">
      <span>
        {{ facilitatorDimension.advice }}
      </span>
    </div>
    <v-row width="100%" justify="center" align="center" class="ma-0 mt-4 pa-0">
      <v-btn depressed rounded color="#16C62E" class="white--text" :to="{ name: 'plans' }"> ver planes </v-btn>
    </v-row>
  </v-card>
</template>

<script>
export default {
  props: {
    odometer: {
      type: Object,
      default: () => {},
    },
    facilitatorDimension: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      flagRanking: 1,
    };
  },
  computed: {
    mobile() {
      return this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs;
    },
    desktop() {
      return this.$vuetify.breakpoint.md || this.$vuetify.breakpoint.lg || this.$vuetify.breakpoint.xl;
    },
  },
};
</script>

<style lang="scss" scoped>
.text-impacto {
  font-size: 16px;
}
.title-idx-text-nowidth {
  font-size: 23px;
  .element {
    text-transform: lowercase;
  }
  line-height: 30px;
  font-weight: 400;
  text-align: start;
}
.gauge {
  position: relative;
  border-radius: 50%/100% 100% 0 0;
  background-color: var(--color, #a22);
  overflow: hidden;
}
.gauge:before {
  content: "";
  display: block;
  padding-top: 50%; /* ratio of 2:1*/
}
.gauge .chart {
  overflow: hidden;
}
.gauge .mask {
  position: absolute;
  left: 20%;
  right: 20%;
  bottom: 0;
  top: 40%;
  background-color: #fff;
  border-radius: 50%/100% 100% 0 0;
}

.gauge .percentage {
  position: absolute;
  top: -1px;
  left: -1px;
  bottom: 0;
  right: -1px;
  background-color: var(--background, #aaa);
  transform: rotate(var(--rotation));
  transform-origin: bottom center;
  transition-duration: 600;
}
.gauge:hover {
  --rotation: 100deg;
}
.gauge .value {
  position: absolute;
  bottom: 0%;
  left: 0;
  transform: rotate(var(--rotationdiv));
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  > div {
    width: 65%;
    display: flex;
    justify-content: flex-start;
    > div {
      background-color: #333333;
      height: 5px;
      border-radius: 999em !important;
      width: 50%;
      text-align: center;
      display: flex;
      justify-content: flex-end;
      > div {
        height: 12px;
        width: 12px;
        background-color: #333333;
        border-radius: 100%;
        margin-top: -5%;
        margin-right: -8%;
      }
    }
  }
}

.gauge .min {
  position: absolute;
  bottom: 0;
  left: 5%;
}
.gauge .max {
  position: absolute;
  bottom: 0;
  right: 5%;
}
</style>
