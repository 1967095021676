<template>
  <v-card class="pa-5" height="100%">
    <v-row class="ma-0 pa-0">
      <v-col class="ma-0 pa-0" :class="desktop ? 'pr-10' : ''" cols="12" xs="12" sm="12" md="9" lg="9" xl="9">
        <v-row width="100%" justify="start" align="center" class="mb-6 ma-0 pa-0">
          <span style="width: auto !important" class="title-idx-text mr-2">Hoja de Ruta Tway</span>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-img
                v-bind="attrs"
                v-on="on"
                class="image-percentage"
                width="20px"
                max-width="20px"
                :src="require('@/assets/img/icons/info.svg')"
              />
            </template>
            <div style="width: 250px; z-index: 1000 !important">
              <span
                >Mapeamos tu proceso para que tengas una visión global del camino hacia la Transformación Digital</span
              >
            </div>
          </v-tooltip>
        </v-row>
        <timeline :value="score" />
      </v-col>
      <v-col class="ma-0 pa-0" cols="12" md="3">
        <v-img class="image-percentage" :src="require('@/assets/img/timeline.svg')" />
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import Timeline from "@/components/dashboards/IMTD/Timeline";

export default {
  components: {
    Timeline,
  },
  props: {
    score: {
      default: 0,
      type: Number,
    },
  },
  computed: {
    mobile() {
      return this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs;
    },
    desktop() {
      return this.$vuetify.breakpoint.md || this.$vuetify.breakpoint.lg || this.$vuetify.breakpoint.xl;
    },
  },
};
</script>

<style scoped lang="scss"></style>
