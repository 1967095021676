<template>
  <v-card class="pa-5" height="100%">
    <v-row width="100%" justify="center" align="center" class="mb-6 ma-0 pa-0">
      <span class="title-idx-text center-text">Tu Índice de Madurez en Transformación Digital </span>
    </v-row>
    <v-row width="100%" justify="center" align="center" class="mb-6 ma-0 pa-0">
      <v-tooltip right>
        <template v-slot:activator="{ on, attrs }">
          <v-progress-circular
            :rotate="-90"
            :size="180"
            :width="22"
            v-bind="attrs"
            v-on="on"
            :value="Math.round(getScore)"
            color="#16C62E"
          >
            <span class="roboto pertentage-text"> {{ Math.round(getScore) }}%</span>
          </v-progress-circular>
        </template>
        <div style="max-width: 400px" class="pt-3">
          <strong>Transformación en Tway</strong>
          <br />
          <p>
            Proceso adaptativo estratégico para que las empresas ajusten su mentalidad y modelo de negocio, integrando
            facilitadores tecnológicos y capacidades digitales a sus operadores, con el fin de crear valor en la
            Economía Digital y ser competitivos.
          </p>
        </div>
      </v-tooltip>
    </v-row>
    <v-row width="100%" justify="center" align="center" class="mb-6 ma-0 pa-0">
      <span class="title-idx-subtitle">
        <span
          >Nivel
          {{ capitalizeFirstLetter(getLevel) }}
        </span>
      </span>
    </v-row>
    <v-row width="100%" justify="start" align="center" class="mb-1 ma-0 pa-0">
      <span class="roboto body-idx-text mb-2" :class="getCompany.type === 'CLIENT' ? 'three-lines' : ''">
        {{ getDescription }}
      </span>
    </v-row>
    <v-row justify="center" v-if="getCompany.type === 'CLIENT'">
      <v-btn
        v-model="dialog"
        color="deep-purple accent-4 roboto"
        elevation="2"
        outlined
        rounded
        @click="dialog = !dialog"
      >
        Ver más
      </v-btn>
    </v-row>

    <v-dialog v-model="dialog" width="700">
      <v-card>
        <v-card-title class="headline" primary-title> Índice de Madurez en Transformación Digital </v-card-title>

        <v-card-text class="pb-2">
          <span class="text--tway-violet font-weight-bold"> Nivel {{ capitalizeFirstLetter(getLevel) }} </span>
        </v-card-text>
        <v-card-text>
          <span class="black--text">
            {{ getDescription }}
          </span>

          <v-row class="mt-5 mb-2" justify="center">
            <v-btn depressed rounded color="tway-violet" class="white--text" @click="dialog = !dialog"> Aceptar </v-btn>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data: () => ({
    dialog: false,
  }),
  methods: {
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
  },
  computed: {
    ...mapGetters("ImtdStore", ["getScore", "getLevel", "getDescription"]),
    ...mapGetters("CompanyStore", ["getCompany"]),
    mobile() {
      return this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs;
    },
    desktop() {
      return this.$vuetify.breakpoint.md || this.$vuetify.breakpoint.lg || this.$vuetify.breakpoint.xl;
    },
  },
};
</script>

<style lang="scss">
.center-text {
  text-align: center !important;
}
.body-idx-text {
  font-size: 16px;
  line-height: 20px;
  font-weight: 400;
  text-align: start;
  color: rgba(#000, 0.8);
}
.title-idx-text {
  font-size: 23px;
  width: 100%;
  .element {
    text-transform: lowercase;
  }
  line-height: 30px;
  font-weight: 400;
  text-align: start;
}
.title-idx-subtitle {
  font-size: 25px;
  line-height: 30px;
  font-weight: 700;
  text-align: start;
  color: #7319d5;
}
.pertentage-text {
  color: #4a148c;
  font-size: 50px;
  line-height: 82.03px;
  text-align: center;
  font-weight: 700;
}
.three-lines {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  white-space: normal;
}
</style>
