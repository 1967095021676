<template>
  <div>
    <v-row class="pa-0 ma-0">
      <div :style="'margin-left: ' + value + '%;'">
        <v-img
          class="image-percentage"
          style="margin-left: -20px; margin-bottom: -15px; z-index: 2"
          width="40px"
          max-width="40px"
          :src="require('@/assets/img/icons/location.svg')"
        />
      </div>
    </v-row>
    <v-row width="100%" class="pa-0 ma-0" justify="space-between" align="center">
      <v-row class="pa-0 ma-0" justify="space-between">
        <div class="circle-timeline active-circle-timeline">
          <span class="roboto"
            >ANÁLOGO
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-img
                  v-bind="attrs"
                  v-on="on"
                  class="image-percentage"
                  width="20px"
                  max-width="20px"
                  :src="require('@/assets/img/icons/info.svg')"
                />
              </template>
              <div>
                <span>Nivel Básico</span>
              </div>
            </v-tooltip></span
          >
        </div>
        <div class="circle-timeline" :class="value > 40 ? 'active-circle-timeline' : ''">
          <span class="second roboto"
            >DIGITAL
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-img
                  v-bind="attrs"
                  v-on="on"
                  class="image-percentage"
                  width="20px"
                  max-width="20px"
                  :src="require('@/assets/img/icons/info.svg')"
                />
              </template>
              <div>
                <span>Nivel Inicial y Estratégico</span>
              </div>
            </v-tooltip></span
          >
        </div>
        <div class="circle-timeline" :class="value >= 100 ? 'active-circle-timeline' : ''">
          <span class="lastone roboto"
            >EN TRANSFORMACIÓN
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-img
                  v-bind="attrs"
                  v-on="on"
                  class="image-percentage"
                  width="20px"
                  max-width="20px"
                  :src="require('@/assets/img/icons/info.svg')"
                />
              </template>
              <div>
                <span>Nivel Competitivo</span>
              </div>
            </v-tooltip></span
          >
          <v-img
            v-if="value < 100"
            class="circle-timeline"
            style="margin-left: 25px; background: transparent !important"
            :src="require('@/assets/img/icons/right-arrow.svg')"
          />
          <v-img
            v-else
            class="circle-timeline"
            style="margin-left: 25px; background: transparent !important"
            :src="require('@/assets/img/icons/right-arrow-green.svg')"
          />
        </div>
      </v-row>
      <v-progress-linear :value="value" height="5" striped color="#7319D5" />
    </v-row>
  </div>
</template>

<style lang="scss" scoped>
.circle-timeline {
  border-radius: 100%;
  height: 30px;
  width: 30px;
  background: #cccccc;
  margin-bottom: -18px;
  z-index: 1;
  span {
    position: absolute;
    margin-top: 35px;
    font-size: 14px;
    color: #333333;
    display: flex;
    flex-direction: row;
  }
  .lastone {
    margin-left: -100px;
  }
  .second {
    margin-left: -15px;
  }
}
.active-circle-timeline {
  background: #aeea00 !important;
}
.progress {
  width: 100%;
}
</style>

<script>
export default {
  data() {
    return {
      per: 10,
      bufferValue: 20,
    };
  },
  props: {
    value: {
      type: Number,
      default: 0,
      required: true,
    },
  },
  computed: {
    mobile() {
      return this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs;
    },
    desktop() {
      return this.$vuetify.breakpoint.md || this.$vuetify.breakpoint.lg || this.$vuetify.breakpoint.xl;
    },
  },
};
</script>
