<template>
  <div>
    <v-row class="ma-0 pa-0" align="stretch">
      <v-col class="ma-0" cols="12" md="3" height="100%">
        <IMTDSummary />
      </v-col>
      <v-col class="ma-0" cols="12" md="9" height="100%">
        <v-container fluid class="mb-4 ma-0 pa-0" align="stretch" height="100%">
          <IndexByDimensions :series="orderedData.series" :ordered-data="orderedData.dimensions" />
        </v-container>
        <v-container fluid class="ma-0 pa-0" height="100%">
          <RoadMap :score="getScore" />
        </v-container>
      </v-col>
    </v-row>
    <v-row class="ma-0 pa-0" align="stretch">
      <v-col class="ma-0" cols="12" md="6">
        <Benchmark
          :ranking-global-number="getRankingGlobalNumber"
          :ranking-client-year="getRankingYearsClient"
          :ranking-sector-number="getRankingSectorNumber"
          :company="getCompany"
        />
      </v-col>
      <v-col class="ma-0" cols="12" md="6">
        <StrategicChallenge :worse-dimension="worseDimension" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import IMTDSummary from "@/components/dashboards/IMTD/IMTDSummary";
import IndexByDimensions from "@/components/dashboards/IMTD/client/IndexByDimensions";
import RoadMap from "@/components/dashboards/IMTD/client/RoadMap";
import Benchmark from "@/components/dashboards/IMTD/client/Benchmark";
import StrategicChallenge from "@/components/dashboards/IMTD/client/StrategicChallenge";
import { mapGetters } from "vuex";
export default {
  components: {
    IMTDSummary,
    IndexByDimensions,
    RoadMap,
    StrategicChallenge,
    Benchmark,
  },
  props: {
    orderedData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      worseDimension: {},
    };
  },
  created() {
    this.setWorse();
  },
  methods: {
    setWorse() {
      let worseDimension = [];
      worseDimension = this.orderedData.dimensions.sort((a, b) => (a.score > b.score ? 1 : b.score > a.score ? -1 : 0));
      this.worseDimension = worseDimension[0];
    },
  },
  watch: {
    orderedData() {
      this.setWorse();
    },
  },
  computed: {
    ...mapGetters("CompanyStore", ["getCompany"]),
    ...mapGetters("ImtdStore", ["getScore"]),
    ...mapGetters("DigitalTransformationStore", [
      "getRankingSectorNumber",
      "getRankingGlobalNumber",
      "getRankingYearsClient",
    ]),
    mobile() {
      return this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs;
    },
    desktop() {
      return this.$vuetify.breakpoint.md || this.$vuetify.breakpoint.lg || this.$vuetify.breakpoint.xl;
    },
  },
};
</script>

<style lang="scss">
.no-ranking-text {
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  /* or 150% */

  text-align: center;
}

.sub-title-no-ranking {
  font-family: Josefin Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #999999;
}

.no-dashboard-text {
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  text-align: center;
}

.small-btn-text-1 {
  font-size: 10px;
  color: #7319d5;
}

.small-btn-text-2 {
  font-size: 10px;
  color: #999999;
}

.center-text {
  text-align: center !important;
}

.title-idx-text {
  font-size: 23px;
  width: 100%;
  .element {
    text-transform: lowercase;
  }
  line-height: 30px;
  font-weight: 400;
  text-align: start;
}

.title-idx-small {
  font-size: 14px;
  .element {
    text-transform: lowercase;
  }
  line-height: 30px;
  font-weight: 400;
  text-align: start;
}

.place-icon {
  display: flex;
  flex-direction: column;
  align-items: center;
  span {
    margin-bottom: -37px;
    font-size: 26px;
    font-weight: bold;
    color: #7319d5;
  }
}

.place-icon-first {
  display: flex;
  flex-direction: column;
  align-items: center;
  span {
    margin-bottom: -50px;
    font-size: 35px;
    font-weight: bold;
    color: #16c62e;
  }
}

.title-idx-subtitle {
  font-size: 25px;
  line-height: 30px;
  font-weight: 700;
  text-align: start;
  color: #7319d5;
}

.item-title {
  font-size: 12px;
  line-height: 14px;
  width: 100%;
  font-weight: 400;
  text-align: start;
  cursor: pointer;
}

.item-title:hover,
.item-title:focus {
  color: rgba(#000, 0.6);
}

.item-score {
  font-size: 30px;
  width: 100%;
  line-height: 14px;
  font-weight: 400;
  color: #333333;
  text-align: start;
}

.item-score-danger {
  font-size: 30px;
  width: 100%;
  color: #b71c1c;
  line-height: 14px;
  font-weight: 400;
  text-align: start;
}

.center-card {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.body-idx-text {
  font-size: 16px;
  line-height: 20px;
  font-weight: 400;
  text-align: start;
  color: rgba(#000, 0.8);
}

.pertentage-text {
  color: #4a148c;
  font-size: 50px;
  line-height: 82.03px;
  text-align: center;
  font-weight: 700;
}

.pertentage-text-danger {
  color: #b71c1c;
  font-size: 50px;
  line-height: 82.03px;
  text-align: center;
  font-weight: 700;
}

.full-height {
  height: 100%;
}

.border-bottom {
  border-bottom: 1px solid #dedede;
}

.worse-title {
  color: #7319d5;
  text-transform: lowercase;
}

.worse-text {
  font-size: 16px;
}
</style>
