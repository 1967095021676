<template>
  <v-card class="pa-5">
    <v-row width="100%" justify="start" align="center" class="mb-6 ma-0 pa-0">
      <span class="title-idx-text-nowidth">Ranking Tway de </span>
      <span class="title-idx-text-nowidth ml-2 mr-4"> Transformación Digital </span>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-img
            v-bind="attrs"
            v-on="on"
            class="image-percentage"
            width="20px"
            max-width="20px"
            :src="require('@/assets/img/icons/info.svg')"
          />
        </template>
        <div>
          <span>Aquí podrás ver tu posición y las de otras empresas Especialistas en Transformación Digital</span>
        </div>
      </v-tooltip>
    </v-row>
    <v-row justify="center" align="center" v-if="!company.checked">
      <v-img max-width="25%" :src="require('@/assets/img/icons/0_ranking.svg')" />
    </v-row>
    <v-row width="100%" justify="center" align="center" class="mb-2 ma-0 pa-0" v-if="!company.checked">
      <span class="sub-title-no-ranking">Tu Posición en Tway</span>
    </v-row>
    <v-row width="100%" justify="start" align="center" class="mb-1 ma-0 pa-0" v-if="!company.checked">
      <span class="no-ranking-text">Para saber tu posición en Tway, debes completar los datos de tu perfil.</span>
    </v-row>
    <v-row width="100%" justify="center" align="center" class="ma-0 mt-4 pa-0" v-if="!company.checked">
      <v-btn depressed rounded color="#16C62E" class="button-text white--text" :to="{ name: 'complete-profile' }">
        COMPLETAR PERFIL
      </v-btn>
    </v-row>
    <v-row class="mb-6 ma-0 pa-0" width="100%" justify="start" align="center" v-if="company.checked">
      <div class="mr-4 place-icon-first">
        <span>{{ rankingGlobalNumber }}</span>
        <v-img class="image-percentage" width="70px" max-width="70px" :src="require('@/assets/img/icons/place.svg')" />
      </div>
      Tu Posición en Tway
    </v-row>
    <v-container fluid class="ma-0 pa-0" v-if="company.checked">
      <v-row
        v-for="(element, elementIndex) in rankingGlobal"
        :key="elementIndex"
        class="mb-1 ma-0 pa-0"
        width="100%"
        justify="space-between"
        align="center"
      >
        <div
          style="display: flex; flex-direction: row; align-items: center; max-width: 80% !important; overflow: hidden"
        >
          <div :style="'opacity: ' + (1 - elementIndex / 5) + ';'" class="mr-2 place-icon">
            <span>{{ elementIndex + 1 }}</span>
            <v-img
              class="image-percentage"
              width="50px"
              max-width="50px"
              :src="require('@/assets/img/icons/place.svg')"
            />
          </div>
          <span style="color: #333333" class="roboto" v-if="element.name">
            <div style="white-space: nowrap; overflow: hidden">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <span v-bind="attrs" v-on="on">{{ element.name }}</span>
                </template>
                <span>{{ element.name }}</span>
              </v-tooltip>
            </div>
          </span>
        </div>
        <span style="color: #333333" class="roboto">{{ toFixed(element.score * 10, 0) }}%</span>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import dtService from "@/services/dtService";

import CompanyService from "@/services/companyService";
export default {
  props: {
    company: {
      default: () => {},
      type: Object,
    },
  },
  data() {
    return {
      rankingGlobal: [],
      rankingGlobalNumber: 0,
    };
  },
  async created() {
    await this.init();
  },
  methods: {
    async init() {
      await dtService
        .getRanking("SPECIALIST")
        .then((response) => {
          this.rankingGlobal = response.map((e) => {
            e.name = "";
            return e;
          });
          let temp = this.rankingGlobal;
          temp.forEach((e, i) => {
            CompanyService.getCompanyName(e.clientTributaryId)
              .then((companyName) => {
                this.rankingGlobal[i].name = companyName;
              })
              .catch(() => {
                this.rankingGlobal[i].name = "Sin nombre";
              });
          });
        })
        .catch((err) => {
          this.$log.error(err);
        });
      await dtService
        .getPosition()
        .then((response) => {
          this.rankingGlobalNumber = response;
        })
        .catch((err) => {
          this.$log.error(err);
        });
    },
    toFixed(num, fixed) {
      var re = new RegExp("^-?\\d+(?:\\.\\d{0," + (fixed || -1) + "})?");
      return num.toString().match(re)[0];
    },
  },
  computed: {
    mobile() {
      return this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs;
    },
    desktop() {
      return this.$vuetify.breakpoint.md || this.$vuetify.breakpoint.lg || this.$vuetify.breakpoint.xl;
    },
  },
};
</script>

<style lang="scss" scoped>
.title-idx-text-nowidth {
  font-size: 23px;
  .element {
    text-transform: lowercase;
  }
  line-height: 30px;
  font-weight: 400;
  text-align: start;
}

.sub-title-no-ranking {
  font-family: Josefin Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #999999;
}
.no-ranking-text {
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: normal !important;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
}
.place-icon-first {
  display: flex;
  flex-direction: column;
  align-items: center;
  span {
    margin-bottom: -50px;
    font-size: 35px;
    font-weight: bold;
    color: #16c62e;
  }
}
.place-icon {
  display: flex;
  flex-direction: column;
  align-items: center;
  span {
    margin-bottom: -37px;
    font-size: 26px;
    font-weight: bold;
    color: #7319d5;
  }
}
</style>
