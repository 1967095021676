<template>
  <v-row class="mt-3">
    <v-col :cols="desktop ? '3' : '12'">
      <IMTDSummary />
    </v-col>
    <v-col>
      <v-row class="mb-4 pa-0 ma-0">
        <v-col class="ma-0 pa-0" :class="desktop ? 'pr-4' : 'pb-4'" cols="12" md="6">
          <RankingTD :company="getCompany" />
        </v-col>
        <v-col class="ma-0 pa-0" cols="12" md="6">
          <ImpactBySector :odometer="{ percentage: 0, dimensions: [] }" :facilitator-dimension="getDimensions[1]" />
        </v-col>
      </v-row>
      <v-row class="mb-4 pa-0 ma-0">
        <v-col class="ma-0 pa-0" :class="desktop ? 'pr-4' : 'pb-4'" cols="12" md="7">
          <BenchmarckSpecialist
            :company="getCompany"
            :ranking-client-years="getRankingYearsClient"
            :ranking-client-size="getCompanyRankingSize"
            :client-level="getLevel"
          />
        </v-col>
        <v-col class="ma-0 pa-0" cols="12" md="5">
          <PopularServices />
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<style scoped lang="scss"></style>

<script>
import RankingTD from "@/components/dashboards/IMTD/specialist/RankingTD";
import ImpactBySector from "@/components/dashboards/IMTD/specialist/ImpactBySector";
import BenchmarckSpecialist from "@/components/dashboards/IMTD/specialist/BenchmarckSpecialist";
import PopularServices from "@/components/dashboards/IMTD/specialist/PopularServices";
import IMTDSummary from "@/components/dashboards/IMTD/IMTDSummary";
import { mapGetters } from "vuex";

export default {
  components: {
    RankingTD,
    ImpactBySector,
    BenchmarckSpecialist,
    PopularServices,
    IMTDSummary,
  },
  computed: {
    ...mapGetters("CompanyStore", ["getCompany", "getCompanyOdometer", "getCompanyRankingSize"]),
    ...mapGetters("ImtdStore", ["getDimensions", "getLevel"]),
    ...mapGetters("DigitalTransformationStore", ["getRankingYearsClient"]),

    mobile() {
      return this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs;
    },
    desktop() {
      return this.$vuetify.breakpoint.md || this.$vuetify.breakpoint.lg || this.$vuetify.breakpoint.xl;
    },
  },
};
</script>
