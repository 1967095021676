<template>
  <v-container class="pa-0 ma-0 pt-2 pb-4" fluid>
    <v-row v-for="(i, index) in values" :key="index" class="ma-0 pa-0">
      <v-col class="pa-0 ma-0 border-right" cols="6">
        <v-row class="ma-0 pa-0" justify="space-between" align="center">
          <v-row class="ma-0 pr-4 pa-0" justify="end" style="max-width: 100%" align="center">
            <span
              class="pa-0 title-element roboto text-uppercase"
              style="max-width: 100%; height: 50px; overflow: hidden; max-height: 40px"
              >{{ i.name }}</span
            >
          </v-row>
        </v-row>
      </v-col>
      <v-col
        :class="index == 0 ? 'border-top-box' : index == values.length - 1 ? 'border-bottom-box' : ''"
        class="pa-0 ma-0 border-right"
        cols="6"
        justify="center"
        align="center"
      >
        <div class="max-center">
          <v-progress-linear :value="Math.round(i.score)" height="80%" background-color="transparent" color="#16C62E">
            <strong :style="Math.round(i.score) >= 60 ? 'color: #fff;' : 'color: #7319D5;'"
              >{{ Math.round(i.score) }}%</strong
            >
          </v-progress-linear>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<style lang="scss" scoped>
.border-bottom {
  border-bottom: 1px solid #f1f1f1;
}
.percentage-element {
  font-size: 30px;
  color: #333333;
}
.title-element {
  color: #000000;
  font-size: 13px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  text-align: right;
}
.max-center {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  .v-progress-linear {
    display: flex !important;
    align-items: center !important;
  }
}
.image-percentage {
  cursor: pointer;
}
.red-color {
  color: #b71c1c !important;
}
.border-right {
  border-right: 2px solid #b98cea;
}
.border-top-box {
  border-top: 2px solid #b98cea;
}
.border-bottom-box {
  border-bottom: 2px solid #b98cea;
}
</style>

<script>
export default {
  props: {
    values: {
      type: [Array, Object],
      default: () => [],
      required: true,
    },
  },
  computed: {
    mobile() {
      return this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs;
    },
    desktop() {
      return this.$vuetify.breakpoint.md || this.$vuetify.breakpoint.lg || this.$vuetify.breakpoint.xl;
    },
    left() {
      return !!this.$slots.left;
    },
  },
};
</script>
