<template>
  <v-container fluid>
    <v-row
      v-for="(i, index) in values"
      :key="index"
      :class="index < values.length - 1 ? 'border-bottom' : ''"
      class="ma-0 py-2 pa-0"
    >
      <v-col class="pa-0 ma-0" cols="3" justify="center" align="center">
        <v-img width="60%" max-width="40px" :src="require('@/assets/img/icons/' + images[index])" />
      </v-col>
      <v-col class="pa-0 ma-0" cols="9">
        <v-row class="ma-0 pa-0" justify="start" align="center">
          <v-row class="ma-0 pa-0" justify="start" align="center">
            <span class="percentage-element roboto mr-3">{{ Math.round(i.score) }}%</span>
            <a
              class="title-element roboto mr-2"
              @click="
                () => {
                  $router.push({
                    name: 'dashboardimension',
                    params: { id: i.code },
                  });
                }
              "
              >{{ i.name }}</a
            >
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-img
                  v-bind="attrs"
                  v-on="on"
                  class="image-percentage"
                  width="20px"
                  max-width="20px"
                  :src="require('@/assets/img/icons/info.svg')"
                />
              </template>
              <div style="width: 250px">
                <span>{{ i.tooltip }}</span>
              </div>
            </v-tooltip>
          </v-row>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<style lang="scss" scoped>
.border-bottom {
  border-bottom: 1px solid #f1f1f1;
}
.percentage-element {
  font-size: 30px;
  color: #333333;
}
.title-element {
  color: #333333;
  font-size: 12px;
}
.image-percentage {
  cursor: pointer;
}
</style>

<script>
export default {
  data() {
    return {
      images: ["bars.svg", "console.svg", "users.svg", "barsup.svg"],
    };
  },
  created() {},
  props: {
    values: {
      type: [Array, Object],
      default: () => [],
      required: true,
    },
  },
  computed: {
    mobile() {
      return this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs;
    },
    desktop() {
      return this.$vuetify.breakpoint.md || this.$vuetify.breakpoint.lg || this.$vuetify.breakpoint.xl;
    },
    left() {
      return !!this.$slots.left;
    },
  },
};
</script>
